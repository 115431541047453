<template>
  <div>
    <div class="div-main-card">
      <div class="div-info">
        <div>
          <div class="side-by-side div-user">
            <ResponsibleUser :genericId="card.id" :multiSelected="false" :responsibleUser="responsibleUser"
              v-model="card.user" urlGet="/api/v1/adm/user/select-all-by-rule-name"
              urlUpdate="/api/v1/planner/card/responsible-user" />
          </div>
          <div class="side-by-side">
            <Tags :cardId="card.id" :initialValue="card.listTag" />
          </div>
        </div>
        <div class="div-edit">
          <InputTextEdit v-model="card.description" :enter="saveCard" />
        </div>
      </div>
      <b-tabs v-model="tabIndex">
        <b-tab title="Atividade">
          <CommentListCreateEdit :cardId="card.id" />
        </b-tab>
        <b-tab title="Tarefa">
          <JobCreateUpdate :cardId="card.id" />
        </b-tab>
        <b-tab title="Locação Vinculada">
          <RentLinked :cardId="card.id" />
        </b-tab>
        <b-tab title="Anexo">
          <FileManager :showInModal="false" source="card" :genericId="card.id" />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>

import ButtonFilter from "@nixweb/nixloc-ui/src/component/forms/ButtonFilter";
import InputTextEdit from "@nixweb/nixloc-ui/src/component/forms/InputTextEdit";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import FileManager from "@nixweb/nixloc-ui/src/component/shared/file-manager/FileManager";
import JobCreateUpdate from './JobCreateUpdate.vue';

import CommentListCreateEdit from '../comment/CommentListCreateEdit.vue';
import RentLinked from './RentLinked.vue'
import ResponsibleUser from './ResponsibleUser.vue';

import Tags from '../tag/Tags.vue'

import { mapGetters, mapActions, mapMutations } from "vuex";


export default {
  name: "CardCreate",
  props: {
    card: Object,
  },
  components: { ButtonFilter, Button, InputText, InputTextEdit, Select, ScrollBar, Tags, ResponsibleUser, CommentListCreateEdit, RentLinked, FileManager, JobCreateUpdate },
  data() {
    return {
      formName: "CardConversationCreate",
      urlUpdateCard: "/api/v1/planner/card/update",
      tabIndex: 0,
      room: this.card.listRoom[0],
      responsibleUser: {},
    };
  },
  mounted() {
    this.responsibleUser = this.card.user
  },
  computed: {
    ...mapGetters("generic", ["event"]),
    ...mapGetters("user", ["hasRule", "hasPermission"]),
    hasRuleCrm() {
      var crm = this.hasPermission("CRM");
      if (crm) {
        return crm.includes("chat");
      }
      return false;
    },
    hasRuleSupport() {
      var support = this.hasPermission("Support");
      if (support) {
        return support.includes("chat");
      }
      return false;
    },
    optionsRoom() {
      return this.card.listRoom.map(room => ({
        title: room.contact.name,
        classIcon: 'fa-solid fa-user',
        value: room,
      }));
    },
  },
  methods: {
    ...mapActions("generic", ["putApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
    saveCard() {
      this.card.tags = [];
      let params = { url: "/api/v1/crm/card/update", obj: this.card, notNotifyToast: true };
      console.log(params);
      this.putApi(params).then((response) => {
        if (response.success) {
          this.addEvent({ name: "cardUpdated" });
        }
        this.removeLoading(["saveCard"]);
      });
    }
  },
  watch: {
    'card.user': {
      handler() {
        this.addEvent({ name: "responsibleUserUpdated" });
      },
      deep: true,
    },
  }
};
</script>

<style scoped>
.div-main-card {
  height: 750px;
}


.div-info {
  margin-bottom: 15px;
  font-size: 15px;
}

.div-scroll {
  height: 470px;
}

.div-user {
  margin-right: 15px;
}

.div-edit {
  margin-top: 15px;
  margin-bottom: 40px;
}
</style>