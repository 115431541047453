<template>
    <div>
        <Panel :module="module" layout="large" :showFilter="false" :showSearch="false" :showButtons="false">
            <div slot="content-main">
                <br>
                <FilterQuery :filters="filters" v-model="stringFilter" />
                <Kanban :stringFilter="stringFilter" />
            </div>
        </Panel>
    </div>
</template>

<script>


import ButtonGroup from "@nixweb/nixloc-ui/src/component/forms/ButtonGroup";
import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Kanban from '../../../components/modules/planner/kanban/Kanban.vue';

import FilterQuery from '@nixweb/nixloc-ui/src/component/shared/filter-builder/FilterQuery.vue'

import { mapMutations, mapGetters, mapState } from "vuex";

export default {
    name: "FrameView",
    components: { ButtonGroup, Panel, Loading, Button, Modal, Kanban, FilterQuery },
    data() {
        return {
            module: this.$route.params.name,
            stringFilter: [],
            filters: [
                {
                    type: "date",
                    title: "Data Cadastro",
                    field: "RegisteredDate",
                    value: "",
                },
                {
                    type: "multiOptions",
                    title: "Cliente",
                    field: "CustomerName",
                    url: "/api/v1/crm/customer/select-all-without-document",
                    propsParams: {},
                    value: "",
                },
                {
                    type: "multiOptions",
                    title: "Etapa",
                    field: "StepName",
                    url: "/api/v1/planner/step/select-all",
                    propsParams: { any: this.$route.params.id },
                    value: "",
                },
                {
                    type: "listOptions",
                    title: "Status",
                    listName: "ListStatusJob",
                    field: "Status",
                    url: "/api/v1/planner/tag/select-all",
                    options: [
                        { text: "Concluída", value: "Concluída" },
                        { text: "Hoje", value: "Hoje" },
                        { text: "Pendente", value: "Pendente" },
                        { text: "Prazo", value: "Prazo" },
                    ],
                    value: [],
                },
                {
                    type: "listOptions",
                    title: "Tag",
                    listName: "ListTag",
                    field: "Name",
                    url: "/api/v1/planner/tag/select-all",
                    value: "",
                },
            ],
        }
    },
    mounted() {
        this.removeLoading(["panel"]);
    },
    computed: {
        ...mapGetters("generic", ["showModal", "event"]),
        ...mapState("user", ["userLogged"]),
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    },
}
</script>
<style scoped></style>