<template>
    <div>
        <ScrollBar :minHeight="450" :maxHeight="450">
            <table class="table table-responsive-xs">
                <thead>
                    <tr>
                        <th class="title-header">Assunto</th>
                        <th class="title-header">Data</th>
                        <th class="title-header text-center">Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody v-for="item in data" :key="item.id">
                    <td class="td-subject" @click="edit(item)">
                        <div class="div-nowrap div-subject">
                            {{ item.subject }}
                        </div>
                    </td>
                    <td>
                        <div class="div-nowrap div-date">
                            {{ item.dateStart }} {{ item.timeStart }} - {{ item.dateEnd }} {{ item.timeEnd }}
                        </div>

                    </td>
                    <td class="text-center">
                        <div v-if="item.status == 'Hoje'" class="job-box job-today side-by-side"></div>
                        <div v-if="item.status == 'Pendente'" class="job-box job-delay  side-by-side"></div>
                        <div v-if="item.status == 'Concluída'" class="job-box job-done side-by-side"></div>
                        <div v-if="item.status == 'Prazo'" class="job-box job-scheduled side-by-side"></div>
                        <div class="side-by-side">
                            {{ item.status }}
                        </div>
                    </td>
                    <td>
                        <Button :_key="item.id" type="danger" classIcon="fa-solid fa-trash" size="small"
                            :params="{ id: item.id }" :clicked="remove" />
                    </td>
                </tbody>
                <tbody v-show="data.length == 0">
                    <tr>
                        <td colspan="12">
                            <span>Nenhum registro adicionado!</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ScrollBar>
    </div>
</template>

<script>

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "JobList",
    props: ["cardId"],
    components: {
        Button, ScrollBar
    },
    data() {
        return {
            urlGet: "/api/v1/planner/card/get-all-job",
            urlDelete: "/api/v1/planner/card/delete-job",
            data: []
        }
    },
    mounted() {
        this.getAll();
    },
    computed: {
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        ...mapActions("generic", ["getApi", "postApi"]),
        getAll() {
            let params = {
                url: this.urlGet, obj: {
                    genericId: this.cardId,
                },
            };
            this.getApi(params).then((response) => {
                this.data = response.content.data;
            });
        },
        edit(item) {
            this.addEvent({ name: "jobCardEdit", data: item });
        },
        remove(item) {
            let params = {
                url: this.urlDelete,
                obj: { id: item.id },
                notNotifyToast: true
            };
            this.postApi(params).then((reponse) => {
                if (reponse.success) {
                    this.removeLoading([item.id]);
                    this.getAll();
                }
            });
        },
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "updatedJob") {
                    this.getAll();
                }
            },
            deep: true,
        },
    },
}

</script>
<style scoped>
.table th,
.table td {
    height: 10px !important;
    padding-left: 5px !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
    border-bottom: 0px !important;
}

.title-header {
    font-size: 14px;
    color: #757d8c;
    font-weight: 400;
    text-transform: uppercase;
}

.td-subject {
    background-color: #F0F4F9;
    cursor: pointer;
}

.div-nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.div-subject {
    width: 400px;
}

.div-date {
    width: 280px;
}

.div-btn {
    margin-top: 15px;
    margin-bottom: 20px;
}

.icon-danger {
    font-size: 20px;
    color: #f0134d;
    cursor: pointer;
}

.icon-danger:hover {
    color: #c70f40;
}

.job-box {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin-right: 8px;
}

.job-delay {
    background-color: red;
}

.job-done {
    background-color: #009183;
}

.job-today {
    background-color: #ff8a1b;
}

.job-scheduled {
    background-color: #3d4eae;
}
</style>
