<template>
  <div class="div-card">
    <div class="div-tags">
      <div class="side-by-side div-photo">
        <img :src="card.avatar" class="user-photo" />
      </div>
      <div class="side-by-side" v-for="item in card.listTag">
        <div class="div-frame text-center" :style="'background:' + item.color">
          <div class="title-name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="limited-title div-name title" @click="execute">
      <i class="fa-solid fa-building"></i>
      {{ card.customerName }}
    </div>
    <div v-if="card.description" class="div-card-description limited-title">
      {{ card.description }}
    </div>
    <div>
      <b-row>
        <b-col sm="10">
          <div v-if="card.totalJob > 0" class="side-by-side div-status-job">
            <div
              class="side-by-side"
              v-for="job in listStatus(card.listStatusJob)"
            >
              <div
                v-if="job.status == 'Hoje'"
                class="job-box job-today side-by-side"
              ></div>
              <div
                v-if="job.status == 'Pendente'"
                class="job-box job-delay side-by-side"
              ></div>
              <div
                v-if="job.status == 'Concluída'"
                class="job-box job-done side-by-side"
              ></div>
              <div
                v-if="job.status == 'Prazo'"
                class="job-box job-scheduled side-by-side"
              ></div>
            </div>
          </div>

          <div v-if="card.totalComment > 0" class="side-by-side div-note">
            <i class="fa-regular fa-comments title"></i>
            <span class="title"> {{ card.totalComment }}</span>
          </div>

          <div v-if="card.totalRent > 0" class="side-by-side div-note">
            <i class="fas fa-exchange-alt title"></i>
            <span class="title"> {{ card.totalRent }} </span>
            <span v-if="hasRule('Rental') && card.showValue">{{
              card.totalValueRent | currency
            }}</span>
          </div>
        </b-col>
        <b-col sm="1">
          <div
            v-if="!isConfirm"
            class="div-icon-trash"
            @click="confirmRemove()"
          >
            <i class="fa-sharp fa-solid fa-trash"></i>
          </div>
          <div v-else>
            <div class="icon icon-confirm side-by-side" @click="remove()">
              <i class="fa-solid fa-circle-check"></i>
            </div>
            <div class="icon icon-close side-by-side" @click="cancel()">
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    Alert,
    Modal,
    Confirmation,
  },
  props: {
    card: {
      type: Object,
      default: () => ({}),
    },
    params: Object,
    clicked: Function,
  },
  data() {
    return {
      urlDeleteAllApi: "/api/v1/planner/card/delete",
      isConfirm: false,
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal"]),
    ...mapGetters("user", ["hasRule"]),
  },
  methods: {
    ...mapMutations("generic", [
      "openModal",
      "hideModal",
      "addEvent",
      "removeLoading",
    ]),
    ...mapActions("generic", ["deleteAllApi"]),
    listStatus(items) {
      const unicos = items.filter(
        (item, index, array) =>
          index === array.findIndex((obj) => obj.status === item.status)
      );
      return unicos;
    },
    execute() {
      if (this.clicked) this.clicked(this.params);
    },
    confirmRemove() {
      this.isConfirm = true;
    },
    cancel() {
      this.isConfirm = false;
    },
    remove() {
      let params = {
        url: this.urlDeleteAllApi,
        selected: [this.card.id],
      };
      this.deleteAllApi(params).then((response) => {
        if (response.success) {
          this.addEvent({ name: "cardRemoved" });
          let self = this;
          setTimeout(function () {
            self.isConfirm = false;
          }, 1000);
        }
        this.removeLoading(["confirm"]);
      });
    },
  },
};
</script>
<style scoped>
.div-card-description {
  margin: 10px;
  padding: 5px 10px 5px 10px;
  font-size: 13px;
  color: #535252;
  background-color: #f0f0f0;
  border-radius: 20px;
}

.div-card {
  margin-bottom: 10px;
  border: 1px solid #e8eaed;
  background-color: white;
  border-radius: 10px !important;
  padding: 10px;
  cursor: move;
}

.user-photo {
  width: 22px;
  height: 22px;
  border-radius: 50px;
  margin-bottom: 3px;
}

.div-check {
  margin-left: 10px;
}

.div-job {
  margin-left: 10px;
}

.div-note {
  margin-left: 5px;
  margin-right: 5px;
  color: rgb(46, 45, 45);
}

.div-chat {
  margin-left: 10px;
  color: darkgreen;
}

.div-name {
  cursor: pointer;
  color: #3f529b;
}

.div-name:hover {
  text-decoration: underline;
}

.limited-title {
  width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.div-icon-trash {
  color: red;
  cursor: pointer;
  opacity: 0.5;
}

.div-icon-trash:hover {
  color: red;
  opacity: 10;
}

.div-tags {
  width: 300px;
}

.div-photo {
  margin-right: 5px;
}

.div-frame {
  border-radius: 20px;
  margin: 2px;
  padding-left: 5px;
  padding-right: 5px;
  height: 18px;
}

.title-name {
  font-size: 10px;
  color: white;
  margin-bottom: 50px;
}

.icon {
  font-size: 15px;
  cursor: pointer;
}

.icon-close {
  color: red;
  margin-left: 2px;
}

.icon-confirm {
  color: green;
}

.job-box {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-right: 4px;
  margin-left: -1px;
}

.job-delay {
  background-color: red;
}

.job-done {
  background-color: #009183;
}

.job-today {
  background-color: #ff8a1b;
}

.job-scheduled {
  background-color: #3d4eae;
}
</style>