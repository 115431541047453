import { render, staticRenderFns } from "./RentLinked.vue?vue&type=template&id=2971ebbe&scoped=true&"
import script from "./RentLinked.vue?vue&type=script&lang=js&"
export * from "./RentLinked.vue?vue&type=script&lang=js&"
import style0 from "./RentLinked.vue?vue&type=style&index=0&id=2971ebbe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2971ebbe",
  null
  
)

export default component.exports