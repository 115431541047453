<template>
    <div>
        <b-row>
            <b-col sm="5">
                <InputText title="Assunto" field="subject" :maxLength="100" v-model="job.subject" />
            </b-col>
            <b-col sm="3">
                <DateTime title="Data" field="date" format="DD/MM/YYYY" type="date" placeholder
                    v-model="job.dateStartEnd" />
            </b-col>
            <b-col sm="2">
                <DateTime title="Hora" field="time" format="HH:mm" type="time" placeholder v-model="job.timeStartEnd" />
            </b-col>
            <b-col sm="2">
                <div class="btn-add">
                    <Button _key="btnAddJob" :type="job.id ? 'success' : 'primary'" :disabled="isValid"
                        :classIcon="job.id ? 'fa-solid fa-floppy-disk' : 'fa-solid fa-plus'" size="small"
                        :clicked="save" />
                </div>
            </b-col>
        </b-row>
        <div>
            <br>
            <JobList :cardId="cardId" />
        </div>
    </div>
</template>

<script>


import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import JobList from './JobList.vue';

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
    name: "JobCreateUpdate",
    props: ["cardId"],
    components: {
        InputText, DateTime, Button, JobList
    },
    data() {
        return {
            urlCreate: "/api/v1/planner/card/create-job",
            urlUpdate: "/api/v1/planner/card/update-job",
            job: {
                id: "",
                subject: "",
                dateStartEnd: "",
                timeStartEnd: "",
                userId: "",
                genericId: "",
            }
        }
    },
    computed: {
        ...mapState("user", ["userLogged"]),
        ...mapGetters("generic", ["event"]),
        isValid() {
            if (this.job.subject && this.job.dateStartEnd) return false;
            return true;
        }
    },
    methods: {
        ...mapActions("generic", ["postApi", "putApi"]),
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        save() {
            this.job.userId = this.userLogged.user.id;
            this.job.listGuest = [];
            this.job.genericId = this.cardId;

            if (this.job.id) {
                let params = { url: this.urlUpdate, obj: this.job };
                this.putApi(params).then((response) => {
                    this.addEvent({ name: "updatedJob" });
                    this.removeLoading(["btnAddJob"]);
                    this.clean();
                });
            } else {
                let params = { url: this.urlCreate, obj: this.job };
                this.postApi(params).then((response) => {
                    this.addEvent({ name: "updatedJob" });
                    this.removeLoading(["btnAddJob"]);
                    this.clean();
                });
            }
        },
        clean() {
            this.job = {
                id: "",
                subject: "",
                dateStartEnd: "",
                timeStartEnd: "",
            }
        }
    },
    watch: {
        event: {
            handler(data) {
                if (data.name == "jobCardEdit") {
                    this.job.id = data.data.id;
                    this.job.subject = data.data.subject;
                    this.job.dateStartEnd = data.data.dateStart;
                    this.job.timeStartEnd = data.data.timeStart;
                }
            },
            deep: true,
        },
    },
}

</script>
<style scoped>
.btn-add {
    margin-top: 38px;
}
</style>