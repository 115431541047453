<template>
    <div>
        <div class="div-current" @click="show = true">
            <img class="img-current" :src="baseUrl + currentValue.photo" alt="">
        </div>
        <div v-if="show" class="main-select" @mouseleave="show = false">
            <div v-for="item in data">
                <div class="div-frame" @click="select(item)">
                    <b-row>
                        <b-col sm="3">
                            <img class="img-card" :src="baseUrl + item.any" alt="">
                        </b-col>
                        <b-col sm="9">
                            <div class="title-name">{{ item.content }}</div>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { mapActions, mapMutations } from "vuex";

export default {
    name: "Select",
    props: ["genericId", "responsibleUser", "urlGet", "urlUpdate", "value"],
    data() {
        return {
            baseUrl: "https://espaco.blob.core.windows.net/nixloc-photo-user/",
            currentValue: {},
            currentValueFilter: [],
            data: [],
            show: false,
            baseParams: {
                search: "",
                currentPage: 1,
                totalPerPage: 20,
            },
        };
    },
    mounted() {
        this.getAll();
        this.currentValue = this.value;
    },
    methods: {
        ...mapActions("generic", ["getApi", "putApi"]),
        ...mapMutations("chat", ["updateUsersFilter"]),
        getAll() {
            let obj = { ...this.baseParams, ...{ any: "CRM" } };
            let params = { url: this.urlGet, obj: obj };
            this.loading = true;
            this.getApi(params).then((response) => {
                response.content.data.forEach(user => {
                    this.data.push(user);
                });
            });
        },
        updateResponsibleUser() {
            let params = {
                url: this.urlUpdate,
                obj: { id: this.genericId, userId: this.currentValue.id },
                notNotifyToast: true
            };
            this.putApi(params).then((response) => { });
        },
        select(item) {
            this.currentValue.id = item.id;
            this.currentValue.photo = item.any;
            if (this.genericId) {
                this.updateResponsibleUser();
                this.$emit("input", this.currentValue);
            }
            this.show = false;
        },
        remove(item) {
            this.currentValueFilter = this.currentValueFilter.filter(x => x.id !== item.id);
            this.$emit("input", this.currentValueFilter);
        }
    },
    watch: {
        responsibleUser: {
            handler(responsibleUser) {
                this.currentValue = responsibleUser;
            },
            deep: true,
        },
    }
};
</script>

<style scoped>
.main-select {
    position: fixed;
    margin-left: 20px;
    min-width: 250px;
    background-color: white !important;
    padding: 15px;
    border: 1px solid #E8EAED;
    border-radius: 10px;
    cursor: pointer;
    z-index: 1000 !important;
}

.div-frame:hover {
    background-color: #F8F9FA !important;
    border-radius: 10px;
}

.div-current {
    cursor: pointer;
}

.title-name {
    margin-top: 13px;
    font-size: 13px;
}

.title-users {
    font-size: 14px;
}

.img-card {
    width: 35px;
    height: 35px;
    margin: 5px;
    border-radius: 50px;
}

.img-current {
    width: 30px;
    height: 30px;
    border-radius: 50px;
}

.icon-filter {
    font-size: 15px;
    cursor: pointer;
}

.icon-close {
    color: red;
    font-size: 13px;
    margin-left: 1px;
    cursor: pointer;
}
</style>