<template>
    <div>
        <div v-if="edit">
            <Color titulo="Cor" v-model="color" />
            <div class="text-center">
                <Button _key="save" type="success" title="Salvar" :disabled="disabled"
                    classIcon="fa-solid fa-floppy-disk" size="small" :clicked="save" />
            </div>
        </div>
        <div v-else :style="'background-color:' + color" class="div-hot">
        </div>
    </div>
</template>
<script>

import Color from "@nixweb/nixloc-ui/src/component/forms/Color";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
    name: "ColorStep",
    props: ["id", "color", "edit"],
    components: { Color, Modal, Button },
    data() {
        return {
            urlUpdate: "/api/v1/planner/step/update-color",
        }
    },
    computed: {
        ...mapGetters("generic", ["showModal"]),
    },
    methods: {
        ...mapMutations("generic", ["openModal", "hideModal", "addEvent", "removeLoading"]),
        ...mapActions("generic", ["putApi"]),
        save() {
            let params = {
                url: this.urlUpdate, obj: {
                    id: this.id,
                    color: this.color,
                },
                notNotifyToast: true
            };
            this.putApi(params).then((response) => {
                if (response.success) {
                    this.hideModal();
                    this.removeLoading(["save"]);
                    this.addEvent({ name: "colorStepUpdated" });
                }
            });
        }
    }
}

</script>
